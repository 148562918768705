import React, { useState } from "react"
import { graphql } from "gatsby"
import chambaStyles from "./chambaTemplate.module.scss"
import SEO from "../components/seo"


export const query = graphql`
  query($id: String!) {
    allAirtable(
      filter: { data: { id: { eq: $id } } }
      sort: { fields: data___type }
    ) {
      edges {
        node {
          data {
            title
            slug
            type
            description
            priority
            imgFile {
              url
            }
          }
        }
      }
    }
  }
`

export default function Chamba(props) {
  const data = props.data.allAirtable.edges.sort(function(a, b) {
    if (a.node.data.priority > b.node.data.priority) {
      return 1
    }
    if (a.node.data.priority < b.node.data.priority) {
      return -1
    }
    return 0
  })
  const jobTitle = data
    .filter(edge => edge.node.data.type === "job")
    .map(data => data.node.data.title)
  const jobSlug = data
    .filter(edge => edge.node.data.type === "job")
    .map(data => data.node.data.slug)
  const jobImg = data
    .filter(edge => edge.node.data.type === "job")
    .map(data => data.node.data.imgFile[0].url)
  const jobDescription = data
    .filter(edge => edge.node.data.type === "jobDescription")
    .map(data => data.node.data.description)
  const jobActivity = data.filter(edge => edge.node.data.type === "jobActivity")
  const jobRestriction = data.filter(
    edge => edge.node.data.type === "jobRestriction"
  )
  const jobSpecs = data.filter(edge => edge.node.data.type === "jobSpecs")
  const jobPayment = data
    .filter(edge => edge.node.data.type === "jobPayment")
    .map(data => data.node.data.description)

  const [form, setForm] = useState(false)
  const [name, setName] = useState("")
  const [career, setCareer] = useState("")
  const [college, setCollege] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [status, setStatus] = useState("")
  const [advantage, setAdvantage] = useState("")
  const [alert, setAlert] = useState("")
  const [alertColor, setAlertColor] = useState("")
  const [submitButton, setSubmitButton] = useState("waiting")
  const [showMail, setShowMail] = useState(false)

  function facebookPixel() {
    window.fbq("track", "JobApplication")
  }

  function show(e) {
    e.preventDefault()
    setForm(true)
  }
  function send(e) {
    e.preventDefault()
    if (!e.target.checkValidity()) {
      setAlertColor("red")
      name.length > 0
        ? setAlert(
            `${name}, por favor verifica que tus datos estén completos y correctos`
          )
        : setAlert(
            `Por favor verifica que tus datos estén completos y correctos`
          )
      setSubmitButton("waiting")
      return
    }
    if (status.length === 0) {
      setAlertColor("red")
      setAlert(`${name}, por favor selecciona tu estatus!`)
      return
    }
    if (phone.length !== 10) {
      setAlertColor("red")
      setAlert(`${name}, por favor escribe tu telefono a 10 dígitos y sin espacios!`)
      return
    }
    setAlertColor("green")
    setAlert(`${name}, estamos enviando tus datos :)`)
    setSubmitButton("sending")
    facebookPixel()
    const data = {
      puesto: jobTitle,
      name: name,
      career: career,
      college: college,
      email: email,
      phone: phone,
      status: status,
      advantage: advantage,
    }
    fetch("https://hook.integromat.com/oe5nzkijwlyywpqxcoe7svdscw1necp3", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
      body: JSON.stringify(data),
    })
      .then(response => {
        if (response.ok) {
          setAlert(
            `Listo ${name}, hemos recibido tus datos! :) Por favor revisa el correo que te enviamos para contestarnos con tu CV a:`
          )
          setShowMail(true)
          setSubmitButton("sent")
        }
      })
      .catch(function(e) {
        console.log(`error ${e}`)
        setAlertColor("red")
        setAlert(
          `${name}, Ocurrió un error :( Sin embargo puedes escribirnos al siguiente correo con tus datos y tu CV`
        )
        setSubmitButton("error")
        setShowMail(true)
      })
  }

  return (
    <div className={chambaStyles.container}>
      <SEO
        title={`${jobTitle}`}
        description={jobDescription}
        image={jobImg}
        url={`/chamba/${jobSlug}`}
      />
      <img src={jobImg} title={jobTitle} alt={`imagen de ${jobTitle}`} />
      <h1>{jobTitle}</h1>
      <button
        type="submit"
        onClick={show}
        className={[
          chambaStyles.submitButton,
          form ? chambaStyles.hideButton : "",
        ].join(" ")}
      >
        Aplicar
      </button>
      <p style={{ color: alertColor, margin: 0 }}>{alert}</p>
      <a
        style={
          showMail
            ? { display: "block", margin: "1em 0" }
            : { display: "none", margin: "1em 0" }
        }
        href="mailto:hr@ppdc.mx"
        target="blank"
        title="Enviar correo a Team P&P DC"
      >
        hr@ppdc.mx
      </a>
      <form
        className={
          form
            ? chambaStyles.applicationForm
            : chambaStyles.applicationFormInactive
        }
        onSubmit={send}
        noValidate
      >
        <input
          type="text"
          value={name}
          onChange={e => setName(e.target.value)}
          className={chambaStyles.input}
          name="name"
          placeholder="Nombre"
          autoComplete="name"
          required
        />
        <input
          type="tel"
          value={phone}
          onChange={e => setPhone(e.target.value)}
          className={chambaStyles.input}
          name="phone"
          placeholder="Teléfono"
          autoComplete="tel"
          required
        />
        <input
          type="text"
          value={email}
          onChange={e => setEmail(e.target.value)}
          className={chambaStyles.input}
          name="email"
          placeholder="Correo"
          autoComplete="email"
          required
        />
        <input
          type="text"
          value={college}
          onChange={e => setCollege(e.target.value)}
          className={chambaStyles.input}
          name="college"
          placeholder="Universidad"
          required
        />
        <input
          type="text"
          value={career}
          onChange={e => setCareer(e.target.value)}
          className={chambaStyles.input}
          name="career"
          placeholder="Carrera y semestre actual (si aplica)"
          required
        />
        <select
          required
          className={chambaStyles.input}
          onChange={e => setStatus(e.target.value)}
        >
          <option selected disabled>
            ¿Eres estudiante o graduado?
          </option>
          <option>Estudiante</option>
          <option>Graduado</option>
        </select>
        <input
          type="text"
          value={advantage}
          onChange={e => setAdvantage(e.target.value)}
          className={chambaStyles.input}
          name="advantage"
          placeholder="¿Por qué eres la persona indicada?"
          required
        />
        {submitButton === "waiting" ? (
          <button type="submit" className={chambaStyles.submitButton} style={{margin:'1em 0 2em'}}>
            Enviar Solicitud de Empleo
          </button>
        ) : submitButton === "sending" ? (
          <button
            disabled
            style={{
              backgroundColor: "transparent",
              color: "green",
              border: "1px solid green",
            }}
            type="submit"
            className={chambaStyles.submitButton}
          >
            Enviando tus datos
          </button>
        ) : submitButton === "sent" ? (
          <button
            disabled
            style={{
              backgroundColor: "green",
              border: "1px solid green",
              color: "white",
            }}
            type="submit"
            className={chambaStyles.submitButton}
          >
            Enviado :)
          </button>
        ) : (
          <button
            disabled
            style={{
              backgroundColor: "red",
              border: "1px solid red",
              color: "black",
            }}
            type="submit"
            className={chambaStyles.submitButton}
          >
            Ocurrió un error :(
          </button>
        )}
      </form>
      <br />
      <h2>Descripción</h2>
      <p>{jobDescription}</p>
      <h3>Responsabilidades</h3>
      <ul>
        {jobActivity.map(activity => {
          return <li>{activity.node.data.description}</li>
        })}
      </ul>
      <h3>Requisitos:</h3>
      <ul>
        {jobRestriction.map(restriction => {
          return <li>{restriction.node.data.description}</li>
        })}
      </ul>
      <h3>Lo que ofrecemos:</h3>
      <ul>
        {jobSpecs.map(spec => {
          return <li>{spec.node.data.description}</li>
        })}
      </ul>
      <h3>Remuneración</h3>
      <p>{jobPayment}</p>
    </div>
  )
}
